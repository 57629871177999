import React, {
  useMemo,
  Dispatch,
  SetStateAction,
  useState,
  useCallback
} from 'react';

import { useSelector } from 'react-redux';

import { Message } from './components/Message/Message';
import { TextArea } from 'components/ui/forms';
import { Button, Tabs, Spinner, Empty } from 'components/ui/general';
import { DealyModal, DealyModalAnchor } from 'components/ui/modals';
import { getIsDealyUser } from 'redux/auth';
import {
  Warranty,
  MaintenanceTask,
  useMaintenanceTaskMessagesQuery,
  useWarrantyMessagesQuery,
  useSendWorkshopMessageMutation,
  WorkshopMessageType
} from 'types';

import styles from './MessageModal.module.scss';

interface MessageModalProps {
  type: 'Warranty' | 'MaintenanceTask';
  id: Warranty['id'] | MaintenanceTask['id'];
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const MessageModal = ({
  type,
  id,
  isOpen,
  setIsOpen
}: MessageModalProps) => {
  const isDealyUser = useSelector(getIsDealyUser);
  const [newMessage, setNewMessage] = useState('');
  const [messageType, setMessageType] = useState(
    isDealyUser ? WorkshopMessageType.Internal : WorkshopMessageType.External
  );

  const messageQuery =
    type === 'Warranty'
      ? useWarrantyMessagesQuery
      : useMaintenanceTaskMessagesQuery;

  const {
    data,
    loading: loadingMessages,
    error: errorLoadingMessages
  } = messageQuery({
    nextFetchPolicy: 'cache-first',
    variables: { id },
    skip: !isOpen
  });

  const [
    sendMessage,
    { loading: loadingSendMessage, error: errorSendMessage }
  ] = useSendWorkshopMessageMutation({
    refetchQueries: ['WarrantyMessages', 'MaintenanceTaskMessages'],
    fetchPolicy: 'no-cache'
  });

  const loading = loadingMessages || loadingSendMessage;

  const { internalMessages, externalMessages } = useMemo(() => {
    if (data && 'warrantyById' in data)
      return {
        internalMessages: data.warrantyById?.internalMessages || [],
        externalMessages: data.warrantyById?.externalMessages || []
      };

    if (data && 'maintenanceTaskById' in data)
      return {
        internalMessages: data.maintenanceTaskById?.internalMessages || [],
        externalMessages: data.maintenanceTaskById?.externalMessages || []
      };

    return { internalMessages: [], externalMessages: [] };
  }, [data]);

  const submitMessage = useCallback(async () => {
    await sendMessage({
      variables: {
        maintenanceTaskId: type === 'MaintenanceTask' ? id : undefined,
        warrantyId: type === 'Warranty' ? id : undefined,
        text: newMessage,
        type: messageType
      }
    });
    setNewMessage('');
  }, [id, newMessage, sendMessage, messageType, type]);

  return (
    <DealyModal
      className={styles.root}
      anchor={DealyModalAnchor.RIGHT}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
    >
      <div>
        <div className={styles.titleRow}>
          <h4>Meddelanden</h4>
          <Button color="clear" onClick={() => setIsOpen(false)}>
            <i className="material-icons">close</i>
          </Button>
        </div>
        {isDealyUser ? (
          <Tabs
            initial={1}
            onActiveTabChange={(activeTab) =>
              setMessageType(activeTab as WorkshopMessageType)
            }
          >
            <Tabs.Panel id={WorkshopMessageType.Internal} label="Intern">
              {internalMessages.map((message) => (
                <Message key={message.id} message={message} />
              ))}
            </Tabs.Panel>
            <Tabs.Panel id={WorkshopMessageType.External} label="Extern">
              <div className={styles.warning}>
                <i className="material-icons">error_outline</i>
                <span>Meddelande till återförsäljare</span>
              </div>
              {externalMessages.map((message) => (
                <Message key={message.id} message={message} />
              ))}
            </Tabs.Panel>
          </Tabs>
        ) : (
          externalMessages.map((message) => (
            <Message key={message.id} message={message} />
          ))
        )}
        <Spinner className={styles.spinner} visible={loading} />
        {errorLoadingMessages && (
          <Empty
            title="Kunde inte hämta meddelanden"
            message="Något gick fel när meddelanden skulle hämtas"
          />
        )}
      </div>
      <div>
        {errorSendMessage && (
          <span className={styles.error}>
            Något gick fel. Kunde inte skicka meddelande
          </span>
        )}
        <TextArea
          className={styles.textArea}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          disabled={loadingSendMessage}
        />
        <Button
          onClick={submitMessage}
          fullWidth
          disabled={!newMessage.length || loading}
          color="alpha"
        >
          Skicka
        </Button>
      </div>
    </DealyModal>
  );
};
